import React, { useState } from "react";
import is from "is_js";
/* eslint-disable */
import withLoadable from "../../components/utils/withLoadable";
const LoadableView = withLoadable({
  loader: () => import("./index")
});

export const withIosSafariOnly = WrappedComp => props => {
  const [isNotOk] = useState(is.ios() && is.not.safari());
  if (isNotOk) {
    return <LoadableView />;
  } else {
    return <WrappedComp {...props} />;
  }
};
