import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/messaging";

export const isSupported = () => firebase.messaging.isSupported();
// export const isSupported = () => false;

export const initializedFirebaseApp = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
});

export const messaging = isSupported()
  ? initializedFirebaseApp.messaging()
  : null;

if (isSupported()) {
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    process.env.REACT_APP_MESSAGING_PUBLIC_VAPI_KEY
  );
}
