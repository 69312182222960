import React, { useState, useEffect } from "react";
// import { Container } from "semantic-ui-react";
import { BrowserBarcodeReader } from "@zxing/library";

const BarcodeScanner = props => {
  const { setBarcode, width } = props;
  // const codeReader = new BrowserQRCodeReader();
  const [codeReader] = useState(new BrowserBarcodeReader());
  const [detect, setDetect] = useState(false);

  useEffect(() => {
    codeReader
      .listVideoInputDevices()
      .then(videoInputDevices => {
        videoInputDevices.forEach(device =>
          console.log(`${device.label}, ${device.deviceId}`)
        );
        if (videoInputDevices.length > 0) {
          // const firstDeviceId = videoInputDevices[0].deviceId;

          codeReader
            .decodeOnceFromVideoDevice(undefined, "video")
            .then(result => {
              setDetect(true);
              setBarcode(result.text);
            })
            .catch(err => console.error(err));
        }
      })
      .catch(err => console.error(err));
    return () => {
      //cleanups
      codeReader.reset();
    };
  }, []);

  return (
    <>
    <video
      id="video"
      width={width}
      height={width * 0.75}
      style={{
        border: "3px solid",
        borderColor: detect ? "lightgreen" : "red"
      }}
    ></video>
    <hr color='red' style={{
      margin: 0,
      position: 'relative',
      top: (width * 0.75 / 2) * -1,
      height: 3
    }}/>
    </>
  );
};

export default BarcodeScanner;
