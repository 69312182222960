import "whatwg-fetch";
import { handleErrors } from "../../components/Common";

export const fetchParcelDropoff = ({
  jwt,
  phoneNumber,
  locationSlug,
  sizeCode,
  payload = "test"
}) => {
  const url = `${process.env.REACT_APP_CMS_HOST}/parcels/${locationSlug}/${sizeCode}`;
  return fetch(url, {
    method: "POST",
    headers: {
      // eslint-disable-next-line quote-props
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    body: JSON.stringify({
      phoneNumber: `${phoneNumber.replace("+", "")}`,
      payload
    })
  })
    .then(handleErrors)
    .then(response => response.json());
};
