import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getUser, getJwt } from "../../views/Auth/reducer";

export const isRoleCourier = (role) => {
  if (role && role.type && role.type === 'courier') {
    return true;
  } else if (role === process.env.REACT_APP_COURIER_ROLE_ID) {
    return true;
  }
  return false;
};

export const isCourier = props => {
  if (props.user && props.user.role) {
    return isRoleCourier(props.user.role);
  }
  return false;
}

export const withUserProps = connect(
  state => ({
    user: getUser(state.auth)
  }),
  () => ({})
);

const showIfIsCourier = WrappedComponent => ({ user, ...props }) => {
  if (isCourier({ user })) {
    return <WrappedComponent {...props} />;
  }
  return null;
};

export const displayIfCourier = compose(
  withUserProps,
  showIfIsCourier
);

export const withJwt = connect(
  state => ({
    jwt: getJwt(state.auth)
  }),
  () => ({})
);