import React from "react";
import { Image, Container, Grid, Segment, Icon } from "semantic-ui-react";
// import { branch, renderComponent } from 'recompose';
// import { compose } from 'redux';
import { Link, Redirect } from "react-router-dom";
// import { withUserProps, isStaff } from '../../components/utils/role';

const Home = () => {
  return (
    <>
      <Segment.Group raised horizontal>
        <Segment>Status: Active</Segment>
        <Segment>Credit: 22</Segment>
      </Segment.Group>
      <Container>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Link to="/courier-dropoff" style={{ color: "black" }}>
                <Icon.Group size="huge">
                  <Icon name="box" />
                  <Icon corner name="angle double down"/>
                </Icon.Group>
                <p>Parcel drop-off</p>
              </Link>
            </Grid.Column>

          </Grid.Row>

          {/* <Grid.Row>
          <Grid.Column>
            <Image
              src="https://react.semantic-ui.com/images/wireframe/square-image.png"
              size="small"
              circular
            />
          </Grid.Column>
          <Grid.Column>
            <Image
              src="https://react.semantic-ui.com/images/wireframe/square-image.png"
              size="small"
              circular
            />
          </Grid.Column>
          <Grid.Column>
            <Image
              src="https://react.semantic-ui.com/images/wireframe/square-image.png"
              size="small"
              circular
            />
          </Grid.Column>
          <Grid.Column>
            <Image
              src="https://react.semantic-ui.com/images/wireframe/square-image.png"
              size="small"
              circular
            />
          </Grid.Column>
        </Grid.Row> */}
        </Grid>
      </Container>
    </>
  );
};

// const enhance = compose(
//   withUserProps,
//   branch(isStaff, renderComponent(() => <Redirect to='/staff-pickup'/>))
// );
export default Home;
