import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Loader, Transition } from 'semantic-ui-react';
import configureStore from './configureStore';
import App from './App';
// import MessengerExtension from './components/MessengerExtension/index';

import registerServiceWorker from './registerServiceWorker';

import Home from './views/Home';
// import Products from './views/Products';
// import Categories from "./views/Categories";
// import Orders from './views/Orders';
// import OrderDetail from './views/OrderDetail';
// import Product from './views/Product';
// import Cart from './views/Cart';
// import Search from './views/Search';
// import Checkout from './views/Checkout';
import Auth from './views/Auth';
import CourierDropoff from './views/CourierDropoff';
import LoadableProfile from './views/Profile/Loadable';
import ProtectedRoute from './components/ProtectedRoute';
// import StaffRoute from './components/StaffRoute';
// import LoadableStaffPickup from './views/StaffPickup/Loadable';
import LoadableUserDetail from './views/UserDetail/Loadable';
import LoadableSignup from './views/Signup/Loadable';
import StorageManager from './components/StorageManager';

// import './index.css';

const { persistor, store } = configureStore();

export const HOME = '/';
export const ORDERS = '/orders';
export const MY_PROFILE = '/my-profile';

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("./firebase-messaging-sw.js")
//     .then(function(registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//       console.log("Service worker registration failed, error:", err);
//     });

//     navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
// }


render(
    <Provider store={store}>
      <HashRouter>
        <PersistGate
          loading={<Loader />}
          // onBeforeLift={onBeforeLift}
          persistor={persistor}
        >
          <StorageManager persistor={persistor}/>
          <App>
            <Switch>
              <Route exact path={HOME} component={Home} />
              <Route path="/signup" component={LoadableSignup} />

              {/* <Route path="/categories" component={Categories} /> */}
              {/* <Route path="/category/:categId" component={Products} /> */}
              {/* <Route path="/product/:productId" component={Product} /> */}
              {/* <Route path="/search/:search" component={Search} /> */}
              {/* <Route path="/cart" component={Cart} /> */}
              {/* <Route path="/checkout" component={Checkout} /> */}
              {/* <ProtectedRoute path={ORDERS} component={Orders} /> */}
              {/* <Route
                path={ORDERS}  
                render={({ match: { path } }) => (
                  <>
                    <ProtectedRoute path={`${path}/`} component={Orders} exact />
                    <ProtectedRoute path={`${path}/:type/:id`} component={OrderDetail} />
                  </>
                )} 
              /> */}
              <Route
                path={MY_PROFILE}  
                render={({ match: { path } }) => (
                  <>
                    <ProtectedRoute path={`${path}/`} component={LoadableProfile} exact />
                    <ProtectedRoute path={`${path}/detail`} component={LoadableUserDetail} />
                  </>
                )} 
              />
              <ProtectedRoute
                path="/courier-dropoff"
                component={CourierDropoff}
              />
              <Route path="/auth/login" component={Auth} />
              {/* <StaffRoute path="/staff-pickup" component={LoadableStaffPickup} /> */}
            </Switch>
          </App>
        </PersistGate>
      </HashRouter>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker();
