import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getUser, getJwt } from "../../views/Auth/reducer";
import { isRoleCourier } from "../utils/role";

export const generateRouteByRole = roleCheckFn => ({
  component: Component,
  jwt,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      !!jwt && user && user.role && roleCheckFn(user.role) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "auth/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const ProtectedRoute = generateRouteByRole(isRoleCourier);

const mapStateToProps = state => ({
  user: getUser(state.auth),
  jwt: getJwt(state.auth)
});

export default connect(mapStateToProps)(ProtectedRoute);
