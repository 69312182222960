import React, { useState, useEffect } from 'react';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { HOME, ORDERS, MY_PROFILE } from '../../';
import { displayIfCourier } from "../../components/utils/role";

const BottomMenu = ({ location, ...props }) => {
  const { pathname } = location;
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
  }, [pathname]);

  return (
    <>
      <Sidebar
        style={{ paddingTop: 0 }}
        as={Menu}
        borderless
        widths={4}
        fluid
        animation="overlay"
        icon="labeled"
        direction="bottom"
        onHide={() => {}}
        visible={visible}
      >
        <Menu.Item as={Link} to={HOME} active={pathname === HOME}>
          <Icon name="home" />
          Home
        </Menu.Item>
        <Menu.Item as={Link} to={ORDERS} active={pathname.indexOf(ORDERS) > -1}>
          <Icon name="unordered list" />
          Activities
        </Menu.Item>
        <Menu.Item disabled as="a">
          <Icon name="camera" />
          Channels
        </Menu.Item>
        <Menu.Item as={Link} to={MY_PROFILE} active={pathname.indexOf(MY_PROFILE) > -1}>
          <Icon name="user" />
          Me
        </Menu.Item>
      </Sidebar>
      <Waypoint
        onEnter={({ previousPosition, currentPosition, event }) => {
          setVisible(!event);
        }}
        onLeave={() => {
          setVisible(true);
        }}
      />
    </>
  );
};

export default compose(withRouter, displayIfCourier)(BottomMenu);
