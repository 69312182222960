import React, { Fragment, useState, useEffect } from "react";
import "whatwg-fetch";
import {
  Button,
  Loader,
  Modal,
  Icon,
  Header,
  Confirm,
  Container,
  Message,
  Dimmer,
  Progress,
  Form,
  Input,
  Grid,
  Radio
} from "semantic-ui-react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Redirect } from "react-router-dom";
import { useQuery } from "urql";
import QrReader from "react-qr-reader";
import { connect } from "react-redux";
import { compose } from "redux";
import withSizes from "react-sizes";
import { getJwt } from "../../views/Auth/reducer";
// import { handleErrors } from "../../components/Common";
import getParameterByName from "../../components/utils/getParameterByName";
import BarcodeScanner from "../../components/BarcodeScanner";
import { getBoxAvailableSizes } from "./query";
// import { withJwt } from "../../components/utils/role";
import { fetchParcelDropoff } from "./actions";

const SCAN = 1;
const SELECT_SIZE = 2;
const SCAN_PARCEL = 3;
const INPUT_RECEIPIENT_NUMBER = 4;
const DROPOFF = 5;

const CourierDropoff = props => {
  const { jwt, sizes } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [headerText, setHeaderText] = useState("Scan locker QR");
  const [data, setData] = useState("");
  const [barcode, setBarcode] = useState("");
  const [confirmBarcode, setConfirmBarcode] = useState(false);
  const [collectionCode, setCollectionCode] = useState("");

  // const [job, setJob] = useState("");
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectSize, setSelectSize] = useState(null);
  const [message, setMessage] = useState("");
  const [redir, setRedir] = useState("");

  const [stage, setStage] = useState(SCAN); //scan, dropoff
  const hasError = !!error;
  const ref = getParameterByName("ref", data);

  const enableScanner = !ref && !loading && stage === SCAN;
  const enableScanParcel = !loading && stage === SCAN_PARCEL;

  const [availableBoxSizes, executeQuery] = useQuery({
    query: getBoxAvailableSizes,
    pause: true
  });

  const scanHandler = code => {
    setBarcode(code);
    setConfirmBarcode(true);
  };

  useEffect(() => {
    if (ref) {
      executeQuery({
        pause: false,
        variables: { slug: ref }
      });
    }
    return () => {
      // cleanup
    };
  }, [data]);

  useEffect(() => {
    if (stage === DROPOFF) {
      setloading(true);
      fetchParcelDropoff({
        sizeCode: selectSize,
        phoneNumber,
        locationSlug: ref,
        jwt,
        payload: barcode
      })
        .then(resp => {
          setloading(false);
          setCollectionCode(resp.message.collectionCode);
          setMessage(`Drop into Box ${resp.message.box.position}`);
          // console.log(resp);
        })
        .catch(e => {
          setError(e);
        });
    }
    return () => {
      // cleanup
    };
  }, [stage]);

  useEffect(() => {
    if (availableBoxSizes.data && availableBoxSizes.data.sizes) {
      setStage(SELECT_SIZE);
    }
    return () => {
      // cleanup
    };
  }, [availableBoxSizes]);

  const collectionText = encodeURIComponent(
    `${process.env.REACT_APP_MAIN_APP_URL}/#/parcel-pickup/${collectionCode}`
  );

  if (redir) {
    return <Redirect to={redir} />;
  }
  if (availableBoxSizes.fetching) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Fetching sizes...</Loader>
      </Dimmer>
    );
  } else if (availableBoxSizes.error) {
    alert("Oh no!");
  }
  return (
    <Fragment>
      <Header as="h2" textAlign="center">
        {stage === SCAN && `Scan locker QR`}
        {stage === SELECT_SIZE && `Select box size`}
        {stage === SCAN_PARCEL && `Scan parcel barcode`}
        {stage === INPUT_RECEIPIENT_NUMBER && `Receipient mobile #`}
        {stage === DROPOFF && `Put it in`}
      </Header>
      <Progress value={stage} total="5" progress="ratio" indicating />
      <Dimmer active={loading} inverted>
        <Loader inverted>Opening a box...</Loader>
      </Dimmer>
      {!hasError && enableScanner && (
        <QrReader
          delay={300}
          onError={setError}
          onScan={setData}
          style={{ width: "100%" }}
        />
      )}

      {!hasError && !confirmBarcode && enableScanParcel && (
        <BarcodeScanner width={sizes.width} setBarcode={scanHandler} />
      )}

      {confirmBarcode && (
        <Container textAlign="center">
          <Form>
            <Form.Field>
              <label>Parcel #</label>
              <input
                value={barcode}
                onChange={e => {
                  setBarcode(e.target.value);
                }}
              />
            </Form.Field>
            <Button
              primary
              onClick={() => {
                setStage(INPUT_RECEIPIENT_NUMBER);
                setConfirmBarcode(false);
              }}
            >
              OK
            </Button>
            <Button
              secondary
              onClick={() => {
                setBarcode("");
                setConfirmBarcode(false);
              }}
            >
              Rescan
            </Button>
          </Form>
        </Container>
      )}

      {!hasError && stage === SELECT_SIZE && (
        <Grid centered>
          <Container textAlign="left">
            <Form>
              <Form.Field>
                Selected size: <b>{selectSize}</b>
              </Form.Field>
              {availableBoxSizes.data.sizes.map(({ id, label, code }) => (
                <Form.Field key={id}>
                  <Radio
                    label={label}
                    name="radioGroup"
                    value={code}
                    checked={selectSize === code}
                    onChange={() => setSelectSize(code)}
                  />
                </Form.Field>
              ))}
              <Form.Button
                disabled={!selectSize}
                onClick={() => {
                  setStage(SCAN_PARCEL);
                }}
              >
                Confirm
              </Form.Button>
            </Form>
          </Container>
        </Grid>
      )}

      {!hasError && stage === INPUT_RECEIPIENT_NUMBER && (
        <Container>
          <PhoneInput
            placeholder="Receipient #"
            country="MY"
            value={phoneNumber}
            name="phoneNumber"
            onChange={setPhoneNumber}
          />
          <br />
          <br />
          <Container textAlign="center">
            <Button
              positive
              disabled={!phoneNumber}
              onClick={() => {
                setShowConfirm(true);
              }}
            >
              Send notification
            </Button>
          </Container>
        </Container>
      )}

      {stage === DROPOFF && (
        <Container>
          <Message positive icon>
            <Icon name="check" />
            <Message.Content>
              <p>{message}</p>
            </Message.Content>
          </Message>
          {collectionCode && (
            <>
              <Button
                as={"a"}
                positive
                fluid
                href={`https://wa.me/${phoneNumber.replace(
                  "+",
                  ""
                )}?text=${collectionText}`}
              >
                <Icon name="whatsapp" /> Send collection code
              </Button>
              <br />
              <br />
            </>
          )}
          <Button fluid onClick={() => setRedir("/")}>
            Done
          </Button>
        </Container>
      )}

      <Confirm
        open={showConfirm}
        content={`Are you sure ${phoneNumber} ?`}
        onCancel={() => {
          setShowConfirm(false);
        }}
        onConfirm={() => {
          setShowConfirm(false);
          setStage(DROPOFF);
        }}
      />
      <Modal basic size="small" open={hasError}>
        <Header icon="archive" content="Sorry, no box are available" />
        <Modal.Content>
          <p>No boxes are available at the moment. Call for support.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" inverted onClick={() => setError("")}>
            <Icon name="checkmark" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  jwt: getJwt(state.auth)
});

const mapSizesToProps = sizes => ({
  sizes
});

const enhance = compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps)
);

export default enhance(CourierDropoff);
