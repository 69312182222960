export const getBoxAvailableSizes = `
query GetSizes($slug: String){
    sizes(
      where: {
        boxes: {
          locker: { location: { slug: $slug } }
          type: { code: parcels }
          isAvailable: true
          isError: false
        }
      }
    ) {
      _id
      id
      code
      label
      createdAt
      updatedAt
    }
  }
  
  `;